var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg class=\"svg-icon icon-"
    + alias4(((helper = (helper = lookupProperty(helpers,"iconId") || (depth0 != null ? lookupProperty(depth0,"iconId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconId","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":36}}}) : helper)))
    + "\" width=\"16\" height=\"16\" focusable=\"false\">\n	<use xlink:href=\"/typo3conf/ext/project/Resources/Public/Images/Icons/efa.svg#"
    + alias4(((helper = (helper = lookupProperty(helpers,"iconId") || (depth0 != null ? lookupProperty(depth0,"iconId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconId","hash":{},"data":data,"loc":{"start":{"line":2,"column":79},"end":{"line":2,"column":89}}}) : helper)))
    + "\"></use>\n</svg>\n";
},"useData":true});