import $ from 'jquery';
import config from '../config.mjs';

export default class Footer {
  constructor() {
    initCountrySwitcher.call(this);
  }
}

/**
 * Copy from nm/GeneralInit.js
 * NX-5185: SEO-friendly country switch
 */
function initCountrySwitcher() {
  const $countrySwitchButtons = $('.footer__country-flag');

  $countrySwitchButtons.attr('disabled', false);
  $countrySwitchButtons.on('click', (event) => {
    const $button = $(event.target);
    let targetUrl = config.homepageURL_DE;

    if ($button.hasClass('at')) {
      targetUrl = config.homepageURL_AT;
    }

    window.location = targetUrl;
  });
}
