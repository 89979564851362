class Storage {
  constructor() {
    this.storage = window.sessionStorage;
  }

  /**
   * @param {String} name
   * @param {Object} value
   */
  setItem(name, value) {
    this.storage.setItem(name, JSON.stringify(value));
  }

  /**
   * @returns {Object|null}
   */
  getItem(name) {
    return JSON.parse(this.storage.getItem(name));
  }
}

export default (new Storage());
