import $ from 'jquery';
import textTemplate from '../../../html/_includes/components/search-result-text.hbs';
import listTemplate from '../../../html/_includes/components/search-result-list.hbs';
import StoreListConverter from './StoreListConverter.mjs';

export class SearchResultText {
  /**
   * @param {jQuery} $textHolder
   */
  constructor($textHolder) {
    this.$textHolder = $textHolder;
  }

  /**
   * @param {int} itemsCount
   * @param {string} currentLocationName
   */
  set(itemsCount, currentLocationName) {
    const $resultText = $(textTemplate({itemsCount, currentLocationName}));

    this.$textHolder.html($resultText);
    $resultText.focus();
  }
}

export class SearchResultList {
  /**
   * @param {jQuery} $listHolder
   */
  constructor($listHolder) {
    this.$listHolder = $listHolder;
  }

  /**
   * @param {Array} stores
   */
  set(stores) {
    new StoreListConverter(stores).getStores().then((transformedStores) => {
      this.$listHolder.html(
        $(listTemplate({stores: transformedStores}))
      );
    });
  }
}
