import $ from 'jquery';
import Form from './ui/Form.mjs';
import Header from './ui/Header.mjs';
import Footer from './ui/Footer.mjs';
import 'bootstrap/js/dist/collapse.js';
import throttle from 'lodash/throttle.js';

class Ui {
  constructor() {
    this.$window = $(window);
    this.$body = $('body');

    initToTopButton.call(this);
    new Form();
    new Header();
    new Footer();
  }
}

function initToTopButton() {
  const $button = $('#btn-to-top');
  let lastPixelScrolled = 0;

  this.$window.on('scroll.Ui.toTopButton', throttle(
    () => {
      const pixelScrolled = this.$window.scrollTop();
      if (pixelScrolled <= lastPixelScrolled) {
        $button.removeClass('sr-only');
      } else {
        $button.addClass('sr-only');
      }
      lastPixelScrolled = pixelScrolled;
    },
    125,
  ));
}

export default new Ui();
