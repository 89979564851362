export const gridBreakpoints = {
  xs: 0,
  sm: 540,
  md: 740,
  lg: 1020,
  xl: 1440,
};

export const gridGutterWidth = 20;

export const deviceIsMinMd = window.matchMedia(`only screen and (min-width: ${gridBreakpoints.md}px)`);

export const deviceIsMinLg = window.matchMedia(`only screen and (min-width: ${gridBreakpoints.lg}px)`);

export const devicePrefersReducedMotion = window.matchMedia('(prefers-reduced-motion)');
