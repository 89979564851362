import $ from 'jquery';
import GoogleMapsLoader from './GoogleMapsLoader.mjs';
import config from '../config.mjs';
import {getStoreUrl} from '../utilities/store-url.mjs';

export class Map {
  /**
   * @param {Window.jQuery|HTMLElement|*} $node HTML element, in which the map
   *                                            is initialised
   * @returns {Map}
   */
  constructor($node = undefined) {
    this.$node = $node || $('#map-wrapper');

    if (this.$node.length < 1) {
      return this;
    }

    GoogleMapsLoader.load().then((google) => {
      this.google = google;
      this.markers = [];
      this.bounds = new google.maps.LatLngBounds();
      this.googleMap = new google.maps.Map(this.$node.get(0), {
        center: {lat: 51.2, lng: 10.4}, // By default center of Germany
        zoom: 5,
        mapTypeControl: true,
        panControl: true,
        zoomControl: true,
        streetViewControl: false,
      });

      return this;
    }).catch((exception) => {
      console.log('Map constructor - Error GoogleMapsLoader:', exception);
    });
  }

  setCurrentPlace(name, coordinates) {
    const currentMarker = new this.google.maps.Marker({
      map: this.googleMap,
      animation: this.google.maps.Animation.DROP,
      position: coordinates,
      icon:  {
        url: config.imageDirectoryPath + 'Icons/pin.png',
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 32), // Origin 2x = 24x64
        scaledSize: new google.maps.Size(24, 33) // Origin 2x = 48x66
      },
      title: name,
      zIndex: 99, // Above other markers
    });

    this.addMarker(currentMarker);
    this.addBound(coordinates);
  }

  setCurrentStore(name, coordinates) {
    const currentMarker = new this.google.maps.Marker({
      map: this.googleMap,
      animation: this.google.maps.Animation.DROP,
      position: coordinates,
      icon:  {
        url: config.imageDirectoryPath + 'Icons/marker-current.png',
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(35, 61), // Origin 2x = 70x122
        scaledSize: new google.maps.Size(71.5, 64) // Origin 2x = 143x128
      },
      title: name,
      zIndex: 99, // Above other markers
    });

    this.addMarker(currentMarker);
    this.addBound(coordinates);
  }

  setStoresNearby(stores) {
    return new Promise((resolve, reject) => {
      if (stores.length < 1) {
        reject('Stores missing');
      }

      stores.forEach((store, index) => {
        const latLng = {lat: parseFloat(store.lat), lng: parseFloat(store.lng)};

        this.addBound(latLng);

        // Delay placing
        setTimeout(() => {
          const storeMarker = new this.google.maps.Marker({
            map: this.googleMap,
            animation: this.google.maps.Animation.DROP,
            position: latLng,
            icon:  {
              url: config.imageDirectoryPath + `Icons/marker-${index + 1}.png`,
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(35, 61), // Origin 2x = 70x122
              scaledSize: new google.maps.Size(71.5, 64) // Origin 2x = 143x128
            },
            title: store.addressLines[0],
            zIndex: stores.length - index, // From marker first/above to last/below
          });

          this.addMarker(storeMarker);

          // Add redirect to store page on icon click
          storeMarker.addListener('click', () => {
            window.location = getStoreUrl(store);
          });

          if (index === stores.length - 1) {
            resolve();
          }
        }, 100 * index);
      });
    });
  }

  addMarker(marker) {
    this.markers.push(marker);
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  centerMapToBounds() {
    this.googleMap.fitBounds(this.bounds);
  }

  addBound(latLng) {
    this.bounds.extend(latLng);
  }

  clearBounds() {
    this.bounds = new this.google.maps.LatLngBounds();
  }

  stretchMapHeight() {
    const $mapWrapper = this.$node.parents('.content-map-grid');
    $mapWrapper.addClass('js_override-map-height');
  }
}

const DefaultMap = (new Map());

export default DefaultMap;
