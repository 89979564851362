import {Loader as MapLoader} from '@googlemaps/js-api-loader';

export default (new MapLoader({
  apiKey: 'AIzaSyCTEFN7lFC3CppAd7gEnuhNGnpSEj29uXc',
  version: 'weekly',
  libraries: [
    'places'
  ],
  fields: [
    'formatted_address',
    'geometry',
  ],
}));
