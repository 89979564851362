import $ from 'jquery';
import throttle from 'lodash/throttle.js';
import {deviceIsMinMd} from '../utilities/media-queries.mjs';
import Body from './Body.mjs';

const CLASS_SLIDE_ANIMATION_COLLECTION = 'slideFromLeft slideFromRight slideToLeft slideToRight';
const canvasElements = [
  'off-canvas-menu',
  'content-canvas',
  'off-canvas-store',
];
const menuOffCanvasIndex = 0;
const contentCanvasIndex = 1;
let currentOffCanvasIndex = 1;
const $contentCanvas = $('#' + canvasElements[contentCanvasIndex]);

export default class Header {
  constructor() {
    this.$node = $('header');

    initStickyHeader.call(this)
    initOffCanvas.call(this);
  }

  getHeight() {
    return this.$node.height();
  }
}

/**
 * Copy from ui/ui.js->handleStickyHeader()
 */
function initStickyHeader() {
  const $window = $(window);
  $window.on('scroll.Header.stickyHeader', throttle(
    () => {
      const pixelScrolled = $window.scrollTop();
      const totalHeight = this.getHeight();
      const CLASS_FIXED = 'fixed-top';
      const CLASS_STICKY = 'sticky-sm-down sticky-md-up';

      if (pixelScrolled > totalHeight) {
        Body.$body.addClass(CLASS_STICKY);
        // Set Bootstrap default class .fixed-top to allow modal.js
        // fix padding when removing scrollbar
        this.$node.addClass(CLASS_FIXED);
      } else {
        Body.$body.removeClass(CLASS_STICKY);
        this.$node.removeClass(CLASS_FIXED);
      }
    },
    125,
  ));
}

function initOffCanvas() {
  // Handle open
  const $triggers = $('#trigger-off-canvas-menu, #trigger-off-canvas-store');
  $triggers.on('click', handleOffCanvasTrigger.bind(this)).removeClass('invisible');

  // Handle close
  const $closeTriggers = $('.off-canvas__btn-close');
  $closeTriggers.on('click', handleOffCanvasCloseTrigger.bind(this));

  // Handle viewport change
  deviceIsMinMd.addListener(handleViewportChange.bind(this));

  // Handle page shadow click
  $('#page-shadow').on('click', handlePageShadowClick.bind(this));
}

function handleOffCanvasTrigger(event) {
  const $currentTrigger = $(event.currentTarget);
  const targetOffCanvasId = $currentTrigger.attr('aria-controls');
  const isSameAsTarget = (elementId) => elementId === targetOffCanvasId;
  const targetOffCanvasIndex = canvasElements.findIndex(isSameAsTarget);
  const $targetOffCanvas = $('#' + targetOffCanvasId);

  if (targetOffCanvasIndex === currentOffCanvasIndex) {
    closeOffCanvas.call(this, $targetOffCanvas, targetOffCanvasIndex, $currentTrigger);
    return;
  }

  openOffCanvas.call(this, $targetOffCanvas, targetOffCanvasIndex, $currentTrigger);
}

function handleOffCanvasCloseTrigger(event) {
  const $targetOffCanvas = $(event.currentTarget).closest('.off-canvas');
  const targetOffCanvasId = $targetOffCanvas.attr('id');
  const $offCanvasTrigger = $(`[aria-controls="${targetOffCanvasId}"]`);
  const isSameAsTarget = (elementId) => elementId === targetOffCanvasId;
  const targetOffCanvasIndex = canvasElements.findIndex(isSameAsTarget);

  closeOffCanvas.call(this, $targetOffCanvas, targetOffCanvasIndex, $offCanvasTrigger);
}

/**
 *
 * @param {MediaQueryListEvent} deviceIsMinMd
 */
function handleViewportChange(deviceIsMinMd) {
  if (deviceIsMinMd.matches) {
    // Restore content canvas position
    $contentCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION);

    // Hide menu off-canvas when necessary
    const $menuOffCanvas = $('#' + canvasElements[menuOffCanvasIndex]);
    const menuOffCanvasId = $menuOffCanvas.attr('id');
    const $menuOffCanvasTrigger = $(`[aria-controls="${menuOffCanvasId}"]`);

    if (currentOffCanvasIndex === menuOffCanvasIndex) {
      // Update index
      currentOffCanvasIndex = contentCanvasIndex;

      Body.restoreBodyScrolling();

      $menuOffCanvasTrigger.removeClass('is-active');

      // Focus
      $menuOffCanvas.attr('aria-hidden', 'true');
      $('#anchor-top').focus();
    }

    // Hide every time in case store off-canvas was active
    $menuOffCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION);
  }
}

function handlePageShadowClick() {
  const $targetOffCanvas = $('#' + canvasElements[currentOffCanvasIndex]);
  const targetOffCanvasId = $targetOffCanvas.attr('id');
  const $offCanvasTrigger = $(`[aria-controls="${targetOffCanvasId}"]`);

  closeOffCanvas.call(this, $targetOffCanvas, currentOffCanvasIndex, $offCanvasTrigger);
}

function openOffCanvas($targetCanvas, targetOffCanvasIndex, $trigger) {
  const $currentCanvas = $('#' + canvasElements[currentOffCanvasIndex]);
  const currentOffCanvasId = $currentCanvas.attr('id');
  const $currentOffCanvasTrigger = $(`[aria-controls="${currentOffCanvasId}"]`);

  let directionFrom;
  let directionTo;

  if (targetOffCanvasIndex < currentOffCanvasIndex) {
    // Open from left
    directionFrom = 'FromLeft';
    directionTo = 'ToRight';
  }
  if (targetOffCanvasIndex > currentOffCanvasIndex) {
    // Open from right
    directionFrom = 'FromRight';
    directionTo = 'ToLeft';
  }

  // Update index
  currentOffCanvasIndex = targetOffCanvasIndex;

  Body.preventBodyScrolling();

  // Animate
  if (deviceIsMinMd.matches === false) {
    $currentCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION).addClass('slide' + directionTo);
  }
  $targetCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION).addClass('slide' + directionFrom);

  $currentOffCanvasTrigger.removeClass('is-active');
  $trigger.addClass('is-active');

  // Focus
  $currentCanvas.attr('aria-hidden', 'true');
  $targetCanvas.attr('aria-hidden', 'false');
  $targetCanvas.focus();
}

function closeOffCanvas($currentCanvas, currentCanvasIndex, $trigger) {
  let directionFrom;
  let directionTo;

  if (currentCanvasIndex < contentCanvasIndex) {
    // Close to left
    directionFrom = 'FromRight';
    directionTo = 'ToLeft';
  }
  if (currentCanvasIndex > contentCanvasIndex) {
    // Close to right
    directionFrom = 'FromLeft';
    directionTo = 'ToRight';
  }

  // Update index
  currentOffCanvasIndex = contentCanvasIndex;

  // Animate
  $currentCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION).addClass('slide' + directionTo);
  if (deviceIsMinMd.matches === false) {
    $contentCanvas.removeClass(CLASS_SLIDE_ANIMATION_COLLECTION).addClass('slide' + directionFrom);
  }

  Body.restoreBodyScrolling();

  $trigger.removeClass('is-active');

  // Focus
  $currentCanvas.attr('aria-hidden', 'true');
  $contentCanvas.attr('aria-hidden', 'false');
  $trigger.focus();
}
