import {FormSearch} from './FormSearch.mjs';
import $ from 'jquery';
import {Map} from './Map.mjs';
import {SearchResultList, SearchResultText} from './SearchResult.mjs';

export default class FormOffCanvasSearch extends FormSearch {
  /**
   *
   * @param {HTMLFormElement} form
   */
  constructor(form) {
    super(form);
    // Declare own components parts
    this.Map = new Map($('#off-canvas-store-search-map-holder'));
    this.SearchResultText = new SearchResultText($('#off-canvas-store-search-result-text-holder'));
    this.SearchResultList = new SearchResultList($('#off-canvas-store-search-result-list-holder'));
  }

  init() {
    super.init();
    // Show this map, which is hidden by default, only with results
    this.$form.on('result.shown', () => {
      this.Map.$node.closest('.d-none').removeClass('d-none');
    });
  }
}
