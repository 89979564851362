import $ from 'jquery';
import config from '../config.mjs';
import Alert from './Alert.mjs';
import GoogleMapsLoader from './GoogleMapsLoader.mjs';
import Map from './Map.mjs';
import {SearchResultList, SearchResultText} from './SearchResult.mjs';
import {storesIsOpenForBusiness} from '../utilities/store-filters.mjs';
import Storage from './Storage.mjs';
import GeoLocation from './GeoLocation.mjs';
import Autocomplete from './Autocomplete.mjs';
import LoadingIndicator from './LoadingIndicator.mjs';
import {getUrlParams} from '../utilities/url.mjs';

const KEY_ENTER = 13;
const DEFAULT_NEARBYSEARCH_RADIUS = 100;
const DEFAULT_NEARBYSEARCH_MAXITEMS = 10;
const SEARCH_MAXITEMS = 5;

export class FormSearch {
  /**
   *
   * @param {HTMLFormElement} form
   */
  constructor(form) {
    this.Map = Map;
    this.$form = $(form);
    this.$input = this.$form.find('.js_form-search-input');
    this.$errorWrapper = this.$form.find('.js_form-search-error');
    this.SearchResultText = new SearchResultText($('#search-result-text-holder'));
    this.SearchResultList = new SearchResultList($('#search-result-list-holder'));
    this.geocoderIsRunning = false;
  }

  init() {
    const isMainSearch = this.Map === Map;

    handleFormSubmit.call(this);
    handleInputEvents.call(this);
    initGeoLocation.call(this);
    initAutocomplete.call(this);

    if (isMainSearch) {
      // The following code if only for the main form search
      searchWithQueryParams.call(this);
      restoreLastSearch.call(this);
    }
  }

  addAlert($alert) {
    LoadingIndicator.hide();
    this.$errorWrapper.html($alert);
  }

  removeAlert() {
    this.$errorWrapper.empty();
  }

  /**
   *
   * @param {string} name
   * @param {google.maps.LatLng} coordinates
   */
  getStoresNearby(name, coordinates) {
    const url = config.endpointNearBySearch +
      `${coordinates.lat()}/${coordinates.lng()}/${DEFAULT_NEARBYSEARCH_RADIUS}/${DEFAULT_NEARBYSEARCH_MAXITEMS}`;

    $.getJSON(url)
      .done((stores) => {
        stores = stores.filter(storesIsOpenForBusiness).slice(0, SEARCH_MAXITEMS);

        if (stores.length < 1) {
          this.addAlert(new Alert({
            "typeWarning": true,
            "iconId": "exclamation",
            "copytext": `Wir konnten keine Filialen in der Nähe von "${name}" finden. Bitte überprüfen Sie die Adresse.`
          }));
          return;
        }

        this.Map.stretchMapHeight();
        this.Map.clearMarkers();
        this.Map.clearBounds();
        this.Map.setCurrentPlace(name, coordinates);
        this.Map.setStoresNearby(stores).finally(() => {
          // Center map even if there are no further stores (finally)
          this.Map.centerMapToBounds();
        });
        this.SearchResultText.set(stores.length, name);
        this.SearchResultList.set(stores);
        this.$form.trigger('result.shown');
        LoadingIndicator.hide();

        // Save search
        Storage.setItem('lastSearch', {
          name,
          coordinates,
        });
      })
      .fail(() => {
        this.addAlert(new Alert({
          "typeDanger": true,
          "iconId": "exclamation",
          "copytext": 'Leider ist die Suche fehlgeschlagen. Bitte versuchen Sie es erneut.'
        }));
      });
  }
}

function handleFormSubmit() {
  return new Promise((resolve) => {
    this.$form.on('submit', (event) => {
      // Don't submit form
      event.preventDefault();
      LoadingIndicator.show();

      this.removeAlert();

      // Requires an input
      if (this.$input.val().trim().length < 1) {
        this.addAlert(new Alert({
          "typeDanger": true,
          "iconId": "exclamation",
          "copytext": 'Bitte tragen Sie eine Postleitzahl oder Ort ein.'
        }));
        return;
      }

      // Geocode current input
      geocodeData.call(
        this,
        handleGeocoderResult,
        {
          address: this.$input.val().trim(),
          componentRestrictions: {country: config.currentCountyCode}
        },
      );
    });

    resolve();
  });
}

export function handleInputEvents() {
  return new Promise((resolve) => {
    // Workaround to prevent form submit when selection autocomplete suggestion
    this.$input.on('keydown', (event) => {
      // @see https://stackoverflow.com/a/12275591
      if (event.which === KEY_ENTER && $('.pac-container:visible').length) {
        event.preventDefault();
      }
    });

    // Remove alerts, when add new input
    this.$input.on('keypress', () => {
      if (this.$input.length <= 1) {
        this.removeAlert();
      }
    });

    resolve();
  });
}

function initGeoLocation() {
  return new Promise((resolve) => {
    new GeoLocation(
      this.$form.find('.js_form-search-location'),
      (data) => {
        this.removeAlert();
        geocodeData.call(this, handleGeocoderResult, data);
      },
      (error) => {
        this.addAlert(new Alert({
          'typeWarning': true,
          'iconId': 'exclamation',
          'copytext': error,
        }));
      },
    );

    resolve();
  });
}

function initAutocomplete() {
  return new Promise((resolve) => {
    new Autocomplete(
      this.$input,
      this.getStoresNearby.bind(this),
      geocodeData.bind(this, handleGeocoderResult)
    );

    resolve();
  });
}

function searchWithQueryParams() {
  return new Promise((resolve) => {
    const urlParams = getUrlParams(location.search);
    const requestWithSearchQuery = urlParams.hasOwnProperty('sq');

    if (requestWithSearchQuery === false) {
      return;
    }

    LoadingIndicator.show();
    setInputValue.call(this, urlParams.sq);
    geocodeData.call(this, handleGeocoderResult, {
      address: urlParams.sq,
    });

    resolve();
  });
}

function restoreLastSearch() {
  return new Promise((resolve, reject) => {
    const urlParams = getUrlParams(location.search);
    const requestWithSearchQuery = urlParams.hasOwnProperty('sq');

    if (requestWithSearchQuery) {
      // Don't proceed to prevent multiple searches
      return;
    }

    GoogleMapsLoader.load().then((google) => {
      const lastSearch = Storage.getItem('lastSearch');

      if (lastSearch) {
        LoadingIndicator.show();
        const coordinates = new google.maps.LatLng({
          lat: lastSearch.coordinates.lat,
          lng: lastSearch.coordinates.lng,
        });

        setInputValue.call(this, lastSearch.name);
        LoadingIndicator.show();
        this.getStoresNearby(lastSearch.name, coordinates);
        resolve();
      }
    }).catch((exception) => {
      console.log('constructor - Error GoogleMapsLoader:', exception);
      reject(exception);
    });
  });
}

/**
 *
 * @param {function} callbackGeocoderResult
 * @param {google.maps.GeocoderRequest} data https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderRequest
 */
export function geocodeData(callbackGeocoderResult, data) {
  if (this.geocoderIsRunning) {
    // Don't run twice, f.e. within form submit and autocomplete
    // which could start asynchronously
    return;
  }

  GoogleMapsLoader.load().then((google) => {
    this.geocoderIsRunning = true;
    (new google.maps.Geocoder())
      .geocode(data)
      .then(callbackGeocoderResult.bind(this))
      .catch((error) => {
        this.geocoderIsRunning = false;
        this.addAlert(new Alert({
          "typeDanger": true,
          "iconId": "exclamation",
          "copytext": 'Leider konnte keine Adresse ermittelt werden. Bitte überprüfen Sie Ihre Eingabe.'
        }));
      });
  }).catch((exception) => {
    this.geocoderIsRunning = false;
    LoadingIndicator.hide();
    console.log('geocodeData - Error GoogleMapsLoader:', exception);
  });
}

function handleGeocoderResult({results}) {
  if (results.length < 1) {
    this.$input.val('');
    LoadingIndicator.hide();
    return;
  }

  // Get nearest address
  const name = results[0]['formatted_address'];
  const coordinates = results[0].geometry.location;
  setInputValue.call(this, name);

  this.getStoresNearby(name, coordinates);
  this.geocoderIsRunning = false;
}

export function setInputValue(text) {
  this.$input.val(text);
  this.$input.addClass('has-value'); // Show clear button
}
