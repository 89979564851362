import $ from 'jquery';
import config from '../config.mjs';
import GoogleMapsLoader from './GoogleMapsLoader.mjs';
import LoadingIndicator from './LoadingIndicator.mjs';

export default class Autocomplete {
  /**
   * @param {Window.jQuery|HTMLElement|*} $input Input, where to put in the autocomplete
   * @param {function} callbackAutocompleted optional function, which consume two arguments
   *                                         - name: {string}
   *                                         - coordinates: {google.maps.LatLng}
   * @param {function} callbackGeocodeData optional function, which consume one argument:
   *                                       - data: {
   *                                           address: {string},
   *                                           componentRestrictions: {country: config.currentCountyCode}
   *                                         }
   */
  constructor($input, callbackAutocompleted = null, callbackGeocodeData = null) {
    this.$input = $input;
    this.callbackAutocompleted = callbackAutocompleted;
    this.callbackGeocodeData = callbackGeocodeData;

    init.call(this);
  }
}

function init() {
  GoogleMapsLoader.load().then((google) => {
    const autocomplete = new google.maps.places.Autocomplete(this.$input.get(0), {
      componentRestrictions: {country: ['de', 'at']},
      fields: ['formatted_address', 'geometry', 'name'],
    });

    autocomplete.addListener('place_changed', () => {
      LoadingIndicator.show();
      const place = autocomplete.getPlace();

      if (place.geometry) {
        const coordinates = place.geometry.location;
        if (this.callbackAutocompleted) {
          this.callbackAutocompleted.call(null, place['formatted_address'], coordinates);
        } else {
          LoadingIndicator.hide();
        }
      } else {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        if (this.callbackGeocodeData) {
          this.callbackGeocodeData.call(null, {
            address: place.name,
            componentRestrictions: {country: config.currentCountyCode}
          });
        }
      }
    });
  }).catch((exception) => {
    console.log('Autocomplete - Error GoogleMapsLoader:', exception);
  });
}
