import {getCurrentOpeningFromStore} from '../utilities/store-opening.mjs';
import ServerTime from './ServerTime.mjs';
import {getStoreUrl} from '../utilities/store-url.mjs';

export default class StoreListConverter {
  constructor(stores) {
    this.currentDateTime = new Date();
    this.stores = stores;

    return this;
  }

  getStores() {
    return new Promise((resolve) => {
      ServerTime.get()
        .then((serverTime) => {
          // Use server time
          this.currentDateTime = new Date(serverTime.iso);
          resolve(this.stores.map(transformStore.bind(this)));
        })
        .catch(() => {
          // Fallback to without server time
          resolve(this.stores.map(transformStore.bind(this)));
        });
    });
  }
}

function transformStore(store) {
  return {
    url: getStoreUrl(store),
    distance: parseFloat(store.distancekm).toFixed(2) + ' km',
    addressLine: store.addressLines[0],
    postalCode: store.postalCode,
    locality: store.locality,
    currentOpening: renderCurrentOpening.call(this, store),
    campaigns: store.customFeedData.teasers,
  };
}

function renderCurrentOpening(store) {
  const {isOpen, closeTime} = getCurrentOpeningFromStore.call(this, store);

  if (isOpen) {
    return `Schließt um ${closeTime} Uhr`;
  } else {
    return 'Aktuell geschlossen';
  }
}
