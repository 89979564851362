import './modules/webpack-public-path.mjs';

/*
 * Initialize Ui first
 */
import './modules/Ui.mjs';
import $ from 'jquery';
import FormOffCanvasSearch from './modules/components/FormOffCanvasSearch.mjs';

/*
 * Initialize Components
 */
(async () => {
  const $formSearch = $('.js_form-search');
  if ($formSearch.length) {
    const {FormSearch} = await import('./modules/components/FormSearch.mjs');

    $formSearch.each((index, form) => {
      new FormSearch(form).init();
    });
  }

  const $offCanvasFormSearch = $('.js_form-off-canvas-search');
  if ($offCanvasFormSearch.length) {
    const $triggerOffCanvasStore = $('#trigger-off-canvas-store');
    $triggerOffCanvasStore.on('click.initForm', () => {
      $offCanvasFormSearch.each((index, form) => {
        new FormOffCanvasSearch(form).init();
      });

      // Only once
      $triggerOffCanvasStore.off('click.initForm');
    })
  }

  const $pageStoreDetails = $('#page-store-details');
  if ($pageStoreDetails.length) {
    import('./modules/PageStoreDetails.mjs');
  }

  const $resizeableIFrame = $('iframe[is="resizable-iframe"]');
  if ($resizeableIFrame.length) {
    import('./modules/components/ResizeableIFrame.mjs');
  }
})();

import('./modules/components/Tracking.mjs');
