import $ from 'jquery';

export default class Form {
  constructor() {
    this.handleFormClearButton();
  }

  /**
   * Copy from ui/ui.js->handleFormClearBtn()
   */
  handleFormClearButton() {
    const $formClearBtn = $('.js_form-clear-btn');
    const $formClearInput = $('.js_form-clear-input');

    $formClearInput.each((index, element) => {
      $(element).on('blur', (event) => {
        this.inputCheckIfValue($(event.target));
      });
    });

    $formClearBtn.on('click', function() {
      $formClearInput.val('');
      $formClearInput.removeClass('has-value');
      $(this).parent().children('input[type=search]').focus();
    });
  }

  /**
   * Copy from ui/ui.js->inputCheckIfVal()
   */
  inputCheckIfValue($formInput) {
    if ($formInput.val()[0] === ' ') {
      $formInput.val('');
    }
    if ($formInput.val() != '') {
      $formInput.addClass('has-value');
    } else {
      $formInput.removeClass('has-value');
    }
  }
}
