import {isCountryAt} from './utilities/country.mjs';

const configDe = {
  currentCountyCode: 'DE',
  homepageURL_DE: '',
  homepageURL_AT: '',
  imageDirectoryPath: '',
  svgDirectoryPath: '',
};

const configAt = {
  currentCountyCode: 'AT',
};

const config = (() => {
  let tempConfig = {};

  // window.EfConfig is declared as inline-script in project/Configuration/TypoScript/Setup/page.typoscript
  if (window.EfConfig) {
    tempConfig = Object.assign({}, configDe, window.EfConfig);
  }

  if (isCountryAt) {
    tempConfig =  Object.assign({}, tempConfig, configAt);
  }

  return tempConfig;
})();

export default config;
