// @see https://1loc.dev/misc/convert-url-parameters-to-object/
export const getUrlParams = (query) => Array.from(new URLSearchParams(query)).
                                             reduce(
                                               (p, [k, v]) => Object.assign({},
                                                 p, {
                                                   [k]: p[k] ?
                                                     (Array.isArray(p[k]) ?
                                                       p[k] :
                                                       [p[k]]).concat(v) :
                                                     v,
                                                 }), {});
