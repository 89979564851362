export function getCurrentOpeningFromStore(store) {
  const currentDateTime = this.currentDateTime;
  const currentWeekDayString = currentDateTime.toLocaleDateString('en', {weekday: 'long'}).toUpperCase();
  const openDateTime = new Date(currentDateTime); // Gets overridden
  const closeDateTime = new Date(currentDateTime); // Gets overridden
  let isOpen = false;
  let openTime = '';
  let closeTime = '';

  store.regularHours.periods.forEach((period) => {
    if (period.openDay !== currentWeekDayString && period.closeDay !== currentWeekDayString) {
      return;
    }

    openTime = period.openTime;
    closeTime = period.closeTime;
    const [openHours, openMinutes] = openTime.split(':');
    const [closeHours, closeMinutes] = closeTime.split(':');
    openDateTime.setHours(openHours, openMinutes, 0);
    closeDateTime.setHours(closeHours, closeMinutes, 0);

    const isClosed = openDateTime > currentDateTime || currentDateTime > closeDateTime;
    if (isClosed) {
      return;
    }

    isOpen = true;
  });

  if (store.temporary_closed === true || store.specialHours?.specialHourPeriods.some(todaySpecialHourIsClosed.bind({currentDateTime}))) {
    isOpen = false;
  }

  // Calculate if store is closing within the next hour
  const minutesLasting = Math.floor((closeDateTime - currentDateTime) / (1000*60));
  const isClosingSoon = minutesLasting <= 60;

  return {
    isOpen,
    isClosingSoon,
    openDateTime,
    closeDateTime,
    openTime,
    closeTime,
  };
}

function todaySpecialHourIsClosed(period) {
  // Currently we only read closed days
  if (period.isClosed !== true) {
    // Don't proceed
    return false;
  }

  const openDateTime = new Date(this.currentDateTime);

  openDateTime.setFullYear(parseInt(period.startDate.year));
  openDateTime.setMonth(parseInt(period.startDate.month) - 1);
  openDateTime.setDate(parseInt(period.startDate.day));

  if (openDateTime.toDateString() === this.currentDateTime.toDateString()) {
    return true;
  }
}
