import $ from 'jquery';
import config from '../config.mjs';

class ServerTime {
  constructor() {
    this.serverTime = new Promise((resolve, reject) => {
      $.getJSON(config.endpointServerTime)
        .done((serverTime) => {
          resolve(serverTime);
        })
        .fail(() => {
          reject(`Request to "${config.endpointServerTime}" has failed`);
        });
    });
  }

  get() {
    return this.serverTime;
  }
}

export default (new ServerTime());
