import $ from 'jquery';
import GoogleMapsLoader from './GoogleMapsLoader.mjs';
import LoadingIndicator from './LoadingIndicator.mjs';

/*
 * geolocation.getCurrentPosition error.code contains the reason:
 * 1 = PERMISSION_DENIED
 * 2 = POSITION_UNAVAILABLE
 * 3 = TIMEOUT
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError#Properties
 */
const GEO_LOCATION_ERRORS = {
  1: 'Leider konnten wir Ihren Standort nicht feststellen. Bitte prüfen Sie Ihre Einstellungen und aktivieren Sie ggf. den Standortzugriff.',
  2: 'Leider konnten wir Ihren Standort nicht feststellen. Aktivieren Sie ggf. den Standortzugriff, oder versuchen Sie es bitte noch einmal.',
  3: 'Leider konnten wir Ihren Standort nicht feststellen. Bitte versuchen Sie es noch einmal.',
};

export default class GeoLocation {
  /**
   * @param {Window.jQuery|HTMLElement|*} $button Button to push
   * @param {function} callbackSuccess Function, which consume one argument
   *                                     - data {
   *                                       location: {google.maps.LatLng}
   *                                     }
   * @param {function} callbackError Function, which consume one argument
   *                                   - errorString
   */
  constructor($button, callbackSuccess, callbackError) {
    this.$button = $button;
    this.callbackSuccess = callbackSuccess;
    this.callbackError = callbackError;

    this.$button.on(
      'click',
      setInputFromUserLocation.bind(this),
    );
  }
}

function setInputFromUserLocation() {
  LoadingIndicator.show();

  navigator.geolocation.getCurrentPosition(
    handleSuccess.bind(this),
    handleError.bind(this),
    {
      // If possible browser/device returns the most accurate position without using cached values
      enableHighAccuracy: true,
      // After waiting for ten seconds without a result, the request results in an error
      timeout: 10000,
      // set to zero to prevent browser/device from returning cached positions
      maximumAge: 0,
    },
  );

  function handleSuccess(position) {
    GoogleMapsLoader.load().then((google) => {
      this.callbackSuccess({
        location: new google.maps.LatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }),
      });
    }).catch((exception) => {
      LoadingIndicator.hide();
      console.log('handleSuccess - Error GoogleMapsLoader:', exception);
    });
  }

  function handleError(error) {
    this.callbackError(GEO_LOCATION_ERRORS[error.code])
  }
}
