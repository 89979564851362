import $ from 'jquery';
import template from '../../../html/_includes/components/alert.hbs';

export default class Alert {
  /**
   * @param {Object} data JSON object
   * @returns {Window.jQuery|HTMLElement|*}
   */
  constructor(data) {
    return $(template(data));
  }
}
