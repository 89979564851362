import $ from 'jquery';
import template from '../../../html/_includes/components/loading-indicator.hbs';
import config from '../config.mjs';

const CLASS_SHOW = 'js_show';
const MIN_PROCESS_RUNTIME = 500;

class LoadingIndicator {
  constructor() {
    this.isShown = false;
    this.$node = $(template({
      "imgUrl": config.imageDirectoryPath + 'loader.gif',
    }));
    this.$node.appendTo('body');
  }

  show() {
    this.isShown = true;

    // Only show for processes which runs longer than the defined runtime
    setTimeout(() => {
      if (!this.isShown) {
        // Don't show
        return;
      }

      this.$node.addClass(CLASS_SHOW);
    }, MIN_PROCESS_RUNTIME)
  }

  hide() {
    this.isShown = false;
    this.$node.removeClass(CLASS_SHOW);
  }
}

export default (new LoadingIndicator())
