import $ from 'jquery';

const CLASS_PREVENT_BODY_SCROLLING = 'js_prevent-body-scrolling';

class Body {
  constructor() {
    this.$body = $('body');
    this.$header = $('header');
  }

  /**
   * Remove scrollbar from body and prevent visual hopping content when removed
   */
  preventBodyScrolling() {
    const scrollbarWidth = _getScrollbarWidth();

    this.$body.addClass(CLASS_PREVENT_BODY_SCROLLING);
    this.$body.css('padding-right', scrollbarWidth);
    this.$header.css('padding-right', scrollbarWidth);
  }

  /**
   * Re-enable scrollbar from body
   */
  restoreBodyScrolling() {
    this.$body.removeClass(CLASS_PREVENT_BODY_SCROLLING);
    this.$body.css('padding-right', '');
    this.$header.css('padding-right', '');
  }
}

/**
 * Fork from Bootstrap modal
 * @return {number}
 */
function _getScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'modal-scrollbar-measure'; // From ~bootstrap/scss/modal SCSS
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
}

export default (new Body());
